import { instance } from "./../modules/instance";

const state = {
  count: "0",
  data: [],
  spinner: false,
  txt: true,
};

const mutations = {
  updatecount(state: any, data: any) {
    state.count = data.count;
  },
  getnoti(state: any) {
    state.data = [];
    state.spinner = true;
    state.txt = false;
    instance
      .get("/get-notification")
      .then((response) => {
        state.data = response.data.data;
        state.count = response.data.count

        if (response.data.data.length > 0) {
          state.spinner = false;
          state.txt = false;
        } else {
          state.spinner = false;
          state.txt = true;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  readnoti(state: any, data: any) {
    instance
      .post("/read-notification", { id: data })
      .then((response) => {
        state.count = response.data.count
      })
      .catch((err) => {
        console.log(err);
      });
  },
};

const getters = {};

export default { namespaced: true, state, mutations, getters };
