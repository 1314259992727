import Home from "../views/home/index.vue";

import AuthMiddleWare from "./middleware/auth";

const dashBoardMeta = { layout: "default", middleware: [AuthMiddleWare] };
const chatMeta = { layout: "blank", middleware: [AuthMiddleWare] };

export const appRouter = [
  {
    path: "/",
    name: "الصفحة الرئيسية",
    component: Home,
    meta: dashBoardMeta,
  },
  {
    path: "/account",
    name: "الصفحة الحساب",
    component: () =>
      import(
        /* webpackChunkName: "account_page" */ "../views/account/account.vue"
      ),
    meta: dashBoardMeta,
  },

  {
    path: "/terms",
    name: "الصفحة الشروط",
    component: () =>
      import(/* webpackChunkName: "terms_page" */ "../views/commen/Terms.vue"),
    meta: dashBoardMeta,
  },

  {
    path: "/reports",
    name: "الصفحة التقارير ",
    component: () =>
      import(
        /* webpackChunkName: "reports_page" */ "../views/reports/reports.vue"
      ),
    meta: dashBoardMeta,
  },

  {
    path: "/culculater",
    name: "الصفحة الحاسبة",
    component: () =>
      import(
        /* webpackChunkName: "culculater_page" */ "../views/commen/culculater.vue"
      ),
    meta: dashBoardMeta,
  },

  {
    path: "/tracking",
    name: "الصفحة التتبع",
    component: () =>
      import(
        /* webpackChunkName: "culculatertracikibg_page" */ "../views/commen/tracking.vue"
      ),
    meta: dashBoardMeta,
  },

  {
    path: "/requests",
    name: "الصفحة الطلبات",
    component: () =>
      import(
        /* webpackChunkName: "requests_page" */ "../views/requests/requests.vue"
      ),
    meta: dashBoardMeta,
  },
  {
    path: "/collection",
    name: "الصفحة التجميع",
    component: () =>
      import(
        /* webpackChunkName: "collection_page" */ "../views/requests/collection.vue"
      ),
    meta: dashBoardMeta,
  },
  {
    path: "/finance-request",
    name: "الصفحة تسوية",
    component: () =>
      import(
        /* webpackChunkName: "finance-request_page" */ "../views/requests/finance.vue"
      ),
    meta: dashBoardMeta,
  },

  {
    path: "/movment",
    name: "الصفحة حركة الحساب",
    component: () =>
      import(
        /* webpackChunkName: "movment_page" */ "../views/account/movment.vue"
      ),
    meta: dashBoardMeta,
  },
  {
    path: "/change-password",
    name: "الصفحة كلمة المرور",
    component: () =>
      import(
        /* webpackChunkName: "change-password_page" */ "../views/account/changepassword.vue"
      ),
    meta: dashBoardMeta,
  },
  {
    path: "/adress",
    name: "الصفحة العناوين",
    component: () =>
      import(
        /* webpackChunkName: "adress_page" */ "../views/account/adress.vue"
      ),
    meta: dashBoardMeta,
  },
  {
    path: "/customer-settings",
    name: "الصفحة اعددات النشاط",
    component: () =>
      import(
        /* webpackChunkName: "customer-settings_page" */ "../views/account/customersetting.vue"
      ),
    meta: dashBoardMeta,
  },
  {
    path: "/shipments",
    name: "الصفحة الطروود",
    component: () =>
      import(
        /* webpackChunkName: "shipments_page" */ "../views/shipments/shipments.vue"
      ),
    meta: dashBoardMeta,
  },
  {
    path: "/shipments-page",
    name: "الصفحة طالطروود",
    component: () =>
      import(
        /* webpackChunkName: "vshipments_page" */ "../views/shipments/shipment-page.vue"
      ),
    meta: dashBoardMeta,
  },
  {
    path: "/add-shipments",
    name: "الصفحة اضافة طالطروود",
    component: () =>
      import(
        /* webpackChunkName: "add-shipments_page" */ "../views/shipments/add-shipments.vue"
      ),
    meta: dashBoardMeta,
  },

  {
    path: "/edit-shipments-page",
    name: "الصفحة تعديل طالطروود",
    component: () =>
      import(
        /* webpackChunkName: "edit-shipments_page" */ "../views/shipments/edit-shipments.vue"
      ),
    meta: dashBoardMeta,
  },

  {
    path: "/finance-page",
    name: "الصفحة المالية",
    component: () =>
      import(
        /* webpackChunkName: "finace_page" */ "../views/finance/finance.vue"
      ),
    meta: dashBoardMeta,
  },
  {
    path: "/finance-invoices-page",
    name: "الصفحة الفواتير",
    component: () =>
      import(
        /* webpackChunkName: "invoices_page" */ "../views/finance/invoices.vue"
      ),
    meta: dashBoardMeta,
  },
  {
    path: "/finance-accountstatemet-page",
    name: "الصفحة كشف الحساب",
    component: () =>
      import(
        /* webpackChunkName: "accountstatemet_page" */ "../views/finance/accountstatemet.vue"
      ),
    meta: dashBoardMeta,
  },

  {
    path: "/about-page",
    name: "الصفحة عن الشركة",
    component: () =>
      import(/* webpackChunkName: "about_page" */ "../views/commen/about.vue"),
    meta: dashBoardMeta,
  },

  {
    path: "/notification-page",
    name: "الصفحة عن الاشعارات",
    component: () =>
      import(
        /* webpackChunkName: "notification_page" */ "../views/commen/notofication.vue"
      ),
    meta: dashBoardMeta,
  },
  {
    path: "/chat",
    name: "الصفحة المحادثة",
    component: () =>
      import(/* webpackChunkName: "chat_page" */ "../views/support/chat.vue"),
    meta: dashBoardMeta,
  },

  {
    path: "/chat-single",
    name: "الصفحة 1 المحادثة",
    component: () =>
      import(
        /* webpackChunkName: "chat-single_page" */ "../views/support/single-chat.vue"
      ),
    meta: chatMeta,
  },

  {
    path: "/sucssifuly-page",
    name: "الصفحة 1 df",
    component: () =>
      import(
        /* webpackChunkName: "sucssifuly-page" */ "../views/shipments/sucssifuly-page.vue"
      ),
    meta: chatMeta,
  },

  {
    path: "/g-reports",
    name: "الصفحة 1 22",
    component: () =>
      import(
        /* webpackChunkName: "g-reports-page" */ "../views/reports/g-reports.vue"
      ),
    meta: dashBoardMeta,
  },

  {
    path: "/g-reports-all",
    name: "الصفحة 1 2212",
    component: () =>
      import(
        /* webpackChunkName: "gg-reports-page" */ "../views/reports/reports-general.vue"
      ),
    meta: dashBoardMeta,
  },

  {
    path: "/g-reports-city",
    name: "الصفحة 1 333",
    component: () =>
      import(
        /* webpackChunkName: "city-reports-page" */ "../views/reports/reports-city.vue"
      ),
    meta: dashBoardMeta,
  },

  {
    path: "/g-reports-resoan",
    name: "الصفحة 1 resoan",
    component: () =>
      import(
        /* webpackChunkName: "resoan-reports-page" */ "../views/reports/reports-resoan.vue"
      ),
    meta: dashBoardMeta,
  },

  {
    path: "/edit-requests",
    name: "الصفحة 1 طلب التعديل",
    component: () =>
      import(
        /* webpackChunkName: "edit-requests-page" */ "../views/requests/edit.vue"
      ),
    meta: dashBoardMeta,
  },

];
