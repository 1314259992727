import auth from './auth'
import layout from './layout'
import user from './user'
import notification from './notification'

import { createStore } from 'vuex'
export default createStore({
  state :{
    /* general */
    public: process.env.BASE_URL,
    /* general */
 },

  modules:{
    auth: auth,
    layout: layout,
    user: user,
    notification: notification

  }
});