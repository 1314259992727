
import axios from 'axios';
import store from '../store/auth';

const token = (store.getters.loggedIn) ? `Bearer ${store.state.access_token}` : '';

export const instance = axios.create({
     baseURL: 'https://apic.landcut.ly/api/auth/customer',
    //  baseURL: 'http://127.0.0.1:7878/api/auth/customer',
    timeout: 50000,
    responseType: 'json',
    headers: { 'Authorization': token, 'Access-Control-Allow-Credential': true,'customer':true },
});
