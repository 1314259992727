import { decryptText } from "../modules/crypt";
import router from "./../router";

export default {
  namespaced: true,
  state: {
    isAuth: localStorage.getItem("access_token") !== null ? true : false,
    access_token: decryptText(localStorage.getItem("access_token")) ?? null,
    morup: false,
  },

  getters: {
    loggedIn(state: any) {
      return state.isAuth;
    },
    isfirst(state: any) {
      return state.firsttime;
    },
    morup(state: any) {
      return state.morup;
    },
  },
  mutations: {
    updatefirst(state: any, data: any) {
      state.firsttime = data;
    },

    logout(state: any) {
      localStorage.removeItem("access_token");
      state.isAuth =
        localStorage.getItem("access_token") !== null ? true : false;
      state.access_token =
        decryptText(localStorage.getItem("access_token")) ?? null;
      state.firsttime =
        localStorage.getItem("firsttime") === null ? true : false;
      router.push("/splash");
    },

    checkstates(state: any, data: any) {
      if (
        data == "update" &&
        router.currentRoute.value.fullPath != "/updatepage"
      ) {
        state.morup = true;
        router.push("/updatepage");
      } else if (
        data == "maintenance" &&
        router.currentRoute.value.fullPath != "/maintenancepage"
      ) {
        state.morup = true;
        router.push("/maintenancepage");
      } else if (
        data == "working" &&
        (router.currentRoute.value.fullPath == "/maintenancepage" ||
          router.currentRoute.value.fullPath == "/updatepage")
      ) {
        state.morup = false;
        router.push("/");
      } else if (data == "working") {
        state.morup = false;
      }
    },
  },
};
