<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div class="pt-3">
        <div class="page-title d-flex">
          <div class="align-self-center me-auto">
            <p class="opacity-80 header-date"></p>
            <h1 class="">مرحبا بك ..!</h1>
          </div>
          <div class="align-self-center ms-auto">
            <a
              @click="getnoti()"
              data-bs-toggle="offcanvas"
              data-bs-target="#menu-notifications"
              class="icon gradient-blue color-white rounded-m shadow-xl"
            >
              <i class="bi bi-bell-fill font-17"></i>
              <em class="badge bg-red-light color-white scale-box">{{
                notification.count
              }}</em>
            </a>
          </div>
        </div>
      </div>

      <Splide
        :options="{
          rewind: false,
          width: '100%',
          padding: '50px',
          perpage: '1',
          type: 'loop',
          arrows: false,
          direction: 'rtl',
          pagination: false,
          paginationKeyboard: false,
          fixedWidth: '300px',
          fixedHeight: '100%',
          gap: '0.2rem',
          autoplay: true,
        }"
        aria-label="Vue Splide Example"
      >
        <SplideSlide v-for="(d, i) in banner" :key="i">
          <div
            class="card card-style bg-18"
            data-card-height="230"
            style="height: 160px; border-style: solid; border-color: gray"
          >
            <div
              class="bg-blackN"
              :style="
                'background-image: url(' +
                d.photo +
                ');height: 500px;background-position: center;background-repeat: no-repeat;background-size: cover;position: relative;'
              "
            ></div>
          </div>
        </SplideSlide>
        <!-- <SplideSlide>
          <div
            class="card card-style bg-18"
            data-card-height="180"
            style="height: 160px; border-style: solid; border-color: gray"
          >
            <div
              class="card-overlay bg-black opacity-80"
              style="
                background-image: url('https://cdn-insights.statusbrew.com/images/2021/07/why_you_need_a_social_media_crm_platform.png');
                background-color: #ccc;
                height: 500px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                position: relative;
              "
            ></div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div
            class="card card-style bg-18"
            data-card-height="180"
            style="height: 160px; border-style: solid; border-color: gray"
          >
            <div
              class="card-overlay bg-black opacity-80"
              style="
                background-image: url('https://builtin.com/sites/www.builtin.com/files/styles/og/public/food-delivery-companies.jpg');
                background-color: #ccc;
                height: 500px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                position: relative;
              "
            ></div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div
            class="card card-style bg-18"
            data-card-height="180"
            style="height: 160px; border-style: solid; border-color: gray"
          >
            <div
              class="card-overlay bg-black opacity-80"
              style="
                background-image: url('https://img.freepik.com/free-vector/way-concept-illustration_114360-1191.jpg');
                background-color: #ccc;
                height: 500px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                position: relative;
              "
            ></div>
          </div>
        </SplideSlide> -->
      </Splide>

      <div class="content pb-2">
        <div class="d-flex text-center">
          <div class="me-auto">
            <a
              href="#"
              data-bs-toggle="offcanvas"
              data-bs-target="#menu-transfer-home"
              class="icon icon-xxl rounded-m bg-theme shadow-m"
              ><i class="font-28 color-green-dark bi bi-arrow-up-circle"></i
            ></a>
            <h6 class="font-13 opacity-80 font-500 mb-0 pt-2">نذكرة تجميع</h6>
          </div>
          <div class="m-auto">
            <a
              href="#"
              data-bs-toggle="offcanvas"
              data-bs-target="#menu-trans-home"
              class="icon icon-xxl rounded-m bg-theme shadow-m"
              ><i class="font-28 color-red-dark bi bi-arrow-down-circle"></i
            ></a>
            <h6 class="font-13 opacity-80 font-500 mb-0 pt-2">تذكرة تسوية</h6>
          </div>
          <div
            data-bs-toggle="offcanvas"
            data-bs-target="#menu-culculate-home"
            Requestse
            class="m-auto"
          >
            <a href="#" class="icon icon-xxl rounded-m bg-theme shadow-m"
              ><i class="font-28 color-blue-dark bi bi-arrow-repeat"></i
            ></a>
            <h6 class="font-13 opacity-80 font-500 mb-0 pt-2">حاسبة الاسعار</h6>
          </div>
          <div class="ms-auto">
            <router-link
              to="/tracking"
              class="icon icon-xxl rounded-m bg-theme shadow-m"
              ><i class="font-28 color-brown-dark bi bi-filter-circle"></i
            ></router-link>
            <h6 class="font-13 opacity-80 font-500 mb-0 pt-2">تتبع</h6>
          </div>
        </div>
      </div>

      <div class="content my-0 mt-n2 px-1">
        <div class="d-flex">
          <div class="align-self-center">
            <h3 class="font-16 mb-2">احصائيات</h3>
          </div>
          <div class="align-self-center ms-auto">
            <!-- <a class="font-12 pt-1">عرض الكل</a> -->
          </div>
        </div>
      </div>

      <div class="card card-style" style="margin-bottom: 100px">
        <div class="content">
          <a class="d-flex py-1">
            <div class="align-self-center">
              <span
                class="icon rounded-s me-2 gradient-orange "
                ><i class="bi bi-box color-white"></i
              ></span>
            </div>
            <div class="align-self-center ps-1">
              <h5 class="pt-1 mb-n1">كل الشحنات</h5>
              <p class="mb-0 font-11 opacity-50">
                <!-- نسبة الزيادة عن الشهر السابق -->
                <span class="copyright-year"></span>
              </p>
            </div>
            <div class="align-self-center ms-auto text-end">
              <h4 class="pt-1 mb-n1 color-red-dark">{{ all }}</h4>
              <p class="mb-0 font-11"></p>
            </div>
          </a>
          <div class="divider my-2 opacity-50"></div>
          <a class="d-flex py-1">
            <div class="align-self-center">
              <span
                class="icon rounded-s me-2   " style="background-color:#1abc9c "
                ><i class="bi bi-check2-square color-white"></i
              ></span>
            </div>
            <div class="align-self-center ps-1">
              <h5 class="pt-1 mb-n1">تم التوصيل</h5>
              <p class="mb-0 font-11 opacity-50">
                <!-- نسبة الزيادة عن الشهر السابق -->
                <span class="copyright-year"></span>
              </p>
            </div>
            <div class="align-self-center ms-auto text-end">
              <h4 class="pt-1 mb-n1 color-blue-dark">{{ done }}</h4>
              <p class="mb-0 font-11"></p>
            </div>
          </a>
          <div class="divider my-2 opacity-50"></div>
          <a class="d-flex py-1">
            <div class="align-self-center">
              <span
                class="icon rounded-s me-2 gradient-red "
                ><i class="bi bi-x-octagon color-white"></i
              ></span>
            </div>
            <div class="align-self-center ps-1">
              <h5 class="pt-1 mb-n1">تعذر التوصيل</h5>
              <p class="mb-0 font-11 opacity-50">
                <!-- نسبة النثصان عن الشهر السابق -->
                <span class="copyright-year"></span>
              </p>
            </div>
            <div class="align-self-center ms-auto text-end">
              <h4 class="pt-1 mb-n1 color-green-dark">{{ field }}</h4>
              <p class="mb-0 font-11"></p>
            </div>
          </a>
          <div class="divider my-2 opacity-50"></div>
          <a class="d-flex py-1">
            <div class="align-self-center">
              <span
                class="icon rounded-s me-2 gradient-yellow "
                ><i class="bi bi-pie-chart-fill color-white"></i
              ></span>
            </div>
            <div class="align-self-center ps-1">
              <h5 class="pt-1 mb-n1">قيد الشحن</h5>
              <p class="mb-0 font-11 opacity-50">
                <!-- مجموع الشحنات في انتظار التوصيل -->
                <span class="copyright-year"></span>
              </p>
            </div>
            <div class="align-self-center ms-auto text-end">
              <h4 class="pt-1 mb-n1 color-green-dark">{{ waiting }}</h4>
              <p class="mb-0 font-11"></p>
            </div>
          </a>
        </div>
      </div>

      <div
        id="menu-transfer-home"
        class="offcanvas offcanvas-bottom offcanvas-detached rounded-m"
        style="display: block; margin-bottom: 100px"
        aria-modal="false"
        role="dialog"
      >
        <div class="menu-size" style="height: 600px">
          <div class="d-flex mx-3 mt-3 py-1">
            <div class="align-self-center">
              <h1 class="mb-0">تذكرة تجميع</h1>
            </div>
            <div class="align-self-center ms-auto">
              <a href="#" class="ps-4" data-bs-dismiss="offcanvas">
                <i class="bi bi-x color-red-dark font-26 line-height-xl"></i>
              </a>
            </div>
          </div>
          <div class="divider divider-margins mt-3"></div>
          <form @submit="savecollection">
            <div class="content mt-0">
              <div class="form-custom form-label form-icon">
                <i class="bi bi-wallet2 font-14"></i>
                <select
                  class="form-select rounded-xs"
                  id="c6"
                  aria-label="Floating label select example"
                  v-model="storedatacol.city"
                  :class="{
                    'is-invalid': v$.storedatacol.city.$error,
                  }"
                >
                  <option value="" v-if="adresses.length < 1">
                    لا يوجد لديك عناوين
                  </option>
                  <option :value="d.id" v-for="(d, i) in adresses" :key="i">
                    {{ d.name }}
                  </option>
                </select>
                <label
                  for="c6"
                  class="form-label-always-active color-highlight font-11"
                  >العنوان</label
                >
              </div>
              <div class="pb-3"></div>
              <div class="form-custom form-label form-icon">
                <i class="bi bi-code-square font-14"></i>
                <input
                  type="number"
                  class="form-control rounded-xs"
                  id="c3"
                  v-model="storedatacol.num"
                  :class="{
                    'is-invalid': v$.storedatacol.num.$error,
                  }"
                  placeholder="10"
                />
                <label
                  for="c3"
                  class="form-label-always-active color-highlight font-11"
                  >عدد الشحنات</label
                >
                <span class="font-10">(اجباري)</span>
              </div>
              <div class="pb-3"></div>
              <div class="form-custom form-label form-icon">
                <i class="bi bi-code-square font-14"></i>
                <input
                  type="text"
                  class="form-control rounded-xs"
                  id="c3"
                  v-model="storedatacol.name"
                  placeholder="مثال : محمد"
                  :class="{
                    'is-invalid': v$.storedatacol.name.$error,
                  }"
                />
                <label
                  for="c3"
                  class="form-label-always-active color-highlight font-11"
                  >المستلم</label
                >
                <span class="font-10">(اجباري)</span>
              </div>
              <div class="pb-3"></div>
              <div class="form-custom form-label form-icon">
                <i class="bi bi-code-square font-14"></i>
                <input
                  type="number"
                  class="form-control rounded-xs"
                  id="c3"
                  v-model="storedatacol.phone"
                  placeholder="0911234567"
                  :class="{
                    'is-invalid': v$.storedatacol.phone.$error,
                  }"
                />

                <label
                  for="c3"
                  class="form-label-always-active color-highlight font-11"
                  >هاتف</label
                >
                <span class="font-10">(اجباري)</span>
              </div>
              <div class="pb-3"></div>
              <div class="form-custom form-label form-icon">
                <i class="bi bi-code-square font-14"></i>
                <input
                  type="text"
                  class="form-control rounded-xs"
                  id="c4"
                  placeholder="ملاحظة"
                />
                <label
                  for="c4"
                  class="form-label-always-active color-highlight font-11"
                  >ملاحظة</label
                >
              </div>
              <div class="pb-2"></div>
              <div class="form-check form-check-custom">
                <label class="form-check-label" for="c2"
                  >الرجاء التاكد من ادخال جميع الطرود المراد تجميعها</label
                >
                <label class="form-check-label" for="c2"
                  >سيتم خصم عمولة التجميع عند التفيد ان وجدت</label
                >

                <i
                  class="is-checked color-green-dark font-14 bi bi-check-circle-fill"
                ></i>
                <i
                  class="is-unchecked color-green-dark font-14 bi bi-circle"
                ></i>
              </div>
            </div>
            <button
              v-if="!colisLoading"
              id="btn-a"
              class="mx-3 mb-3 btn btn-full gradient-green shadow-bg shadow-bg-s"
            >
              تاكيد الطلب
            </button>
            <button
              disabled
              v-if="colisLoading"
              id="btn-a"
              class="mx-3 mb-3 btn btn-full gradient-green shadow-bg shadow-bg-s"
            >
              الرجاء الانتظار
            </button>
            <a
              v-show="false"
              href="#"
              ref="hidesho"
              data-bs-dismiss="offcanvas"
              class="btn btn-full gradient-highlight shadow-bg shadow-bg-s mt-4"
              >Apply Settings</a
            >
          </form>
        </div>
      </div>

      <div
        id="menu-trans-home"
        class="offcanvas offcanvas-bottom offcanvas-detached rounded-m"
        style="display: block; margin-bottom: 100px"
        aria-modal="false"
        role="dialog"
      >
        <div class="menu-size" style="height: 600px">
          <div class="d-flex mx-3 mt-3 py-1">
            <div class="align-self-center">
              <h1 class="mb-0">تذكرة تسوية</h1>
            </div>
            <div class="align-self-center ms-auto">
              <a href="#" class="ps-4" data-bs-dismiss="offcanvas">
                <i class="bi bi-x color-red-dark font-26 line-height-xl"></i>
              </a>
            </div>
          </div>
          <div class="row text-center" style="width: 100%; margin-right: 5px">
            <div class="col-6 mb-n2">
              <div class="card card-style ms-0" style="height: 70px">
                <div class="card-center">
                  <h1 class="font-16">{{ storedatafi.num }} شحنات</h1>
                </div>
                <div class="card-bottom">
                  <p class="font-14 mb-0 opacity-70">عدد الشحنات</p>
                </div>
              </div>
            </div>
            <div class="col-6 mb-n2">
              <div href="#" class="card card-style ms-0" style="height: 70px">
                <div class="card-center">
                  <h1 class="font-16">{{ storedatafi.value }} دينار</h1>
                </div>
                <div class="card-bottom">
                  <p class="font-14 mb-0 opacity-70">القيمة المالية</p>
                </div>
              </div>
            </div>
          </div>
          <div class="divider divider-margins mt-3"></div>
          <form @submit="savefi">
            <div class="content mt-0">
              <div class="form-custom form-label form-icon">
                <i class="bi bi-wallet2 font-14"></i>
                <select
                  class="form-select rounded-xs"
                  id="c6"
                  aria-label="Floating label select example"
                  v-model="storedatafi.city"
                  :class="{
                    'is-invalid': v$.storedatafi.city.$error,
                  }"
                >
                  <option value="" v-if="adresses.length == 0">
                    لا يوجد لديك عناوين
                  </option>
                  <option :value="d.id" v-for="(d, i) in adresses" :key="i">
                    {{ d.name }}
                  </option>
                </select>
                <label
                  for="c6"
                  class="form-label-always-active color-highlight font-11"
                  >العنوان</label
                >
              </div>
              <div class="pb-3"></div>
              <div class="form-custom form-label form-icon">
                <i class="bi bi-code-square font-14"></i>
                <input
                  type="number"
                  class="form-control rounded-xs"
                  id="c3"
                  v-model="storedatafi.value"
                  :class="{
                    'is-invalid': v$.storedatafi.value.$error,
                  }"
                  placeholder="10"
                />
                <label
                  for="c3"
                  class="form-label-always-active color-highlight font-11"
                  >القيمة</label
                >
                <span class="font-10">(اجباري)</span>
              </div>
              <div class="pb-3"></div>
              <div class="form-custom form-label form-icon">
                <i class="bi bi-code-square font-14"></i>
                <input
                  type="text"
                  class="form-control rounded-xs"
                  id="c3"
                  v-model="storedatafi.name"
                  placeholder="مثال : محمد"
                  :class="{
                    'is-invalid': v$.storedatafi.name.$error,
                  }"
                />
                <label
                  for="c3"
                  class="form-label-always-active color-highlight font-11"
                  >المستلم</label
                >
                <span class="font-10">(اجباري)</span>
              </div>
              <div class="pb-3"></div>
              <div class="form-custom form-label form-icon">
                <i class="bi bi-code-square font-14"></i>
                <input
                  type="number"
                  class="form-control rounded-xs"
                  id="c3"
                  v-model="storedatafi.phone"
                  placeholder="0911234567"
                  :class="{
                    'is-invalid': v$.storedatafi.phone.$error,
                  }"
                />

                <label
                  for="c3"
                  class="form-label-always-active color-highlight font-11"
                  >هاتف</label
                >
                <span class="font-10">(اجباري)</span>
              </div>
              <div class="pb-3"></div>
              <div class="form-custom form-label form-icon">
                <i class="bi bi-code-square font-14"></i>
                <input
                  type="text"
                  class="form-control rounded-xs"
                  id="c4"
                  placeholder="ملاحظة"
                />
                <label
                  for="c4"
                  class="form-label-always-active color-highlight font-11"
                  >ملاحظة</label
                >
              </div>
              <div class="pb-2"></div>
              <div class="form-check form-check-custom">
                <i
                  class="is-checked color-green-dark font-14 bi bi-check-circle-fill"
                ></i>
                <i
                  class="is-unchecked color-green-dark font-14 bi bi-circle"
                ></i>
              </div>
            </div>
            <button
              id="btn-a"
              class="mx-3 mb-3 btn btn-full gradient-green shadow-bg shadow-bg-s"
            >
              تاكيد الطلب
            </button>
            <a
              v-show="false"
              href="#"
              ref="hidesho"
              data-bs-dismiss="offcanvas"
              class="btn btn-full gradient-highlight shadow-bg shadow-bg-s mt-4"
              >Apply Settings</a
            >
          </form>
        </div>
      </div>
      <div
        id="menu-culculate-home"
        class="offcanvas offcanvas-bottom offcanvas-detached rounded-m"
        style="display: block; margin-bottom: 100px"
        aria-modal="false"
        role="dialog"
      >
        <div class="menu-size" style="height: 440px">
          <div class="d-flex mx-3 mt-3 py-1">
            <div class="align-self-center">
              <h1 class="mb-0">حاسبة الاسعار</h1>
            </div>
            <div class="align-self-center ms-auto">
              <a href="#" class="ps-4" data-bs-dismiss="offcanvas">
                <i class="bi bi-x color-red-dark font-26 line-height-xl"></i>
              </a>
            </div>
          </div>

          <div class="divider divider-margins mt-3"></div>
          <div class="content mt-0">
            <div class="form-custom form-label form-icon">
              <i class="bi bi-wallet2 font-14"></i>
              <select
                :class="{
                  'is-invalid': v$.culculate.from.$error,
                }"
                v-model="culculate.from"
                class="form-select rounded-xs"
                id="c6"
                aria-label="Floating label select example"
              >
                <option :value="d.id" v-for="(d, i) in from" :key="i">
                  {{ d.name }}
                </option>
              </select>
              <label
                for="c6"
                class="form-label-always-active color-highlight font-11"
                >من</label
              >
            </div>
            <div class="pb-3"></div>
            <div class="form-custom form-label form-icon">
              <i class="bi bi-wallet2 font-14"></i>
              <select
                :class="{
                  'is-invalid': v$.culculate.to.$error,
                }"
                v-model="culculate.to"
                class="form-select rounded-xs"
                id="c6"
                aria-label="Floating label select example"
              >
                <option :value="d.id" v-for="(d, i) in to" :key="i">
                  {{ d.name }}
                </option>
              </select>
              <label
                for="c6"
                class="form-label-always-active color-highlight font-11"
                >الي</label
              >
            </div>
            <div class="pb-3"></div>
            <div class="form-custom form-label form-icon">
              <i class="bi bi-code-square font-14"></i>
              <input
                type="text"
                class="form-control rounded-xs"
                id="c3"
                :class="{
                  'is-invalid': v$.culculate.price.$error,
                }"
                v-model="culculate.price"
                placeholder="قيمة"
              />
              <label
                for="c3"
                class="form-label-always-active color-highlight font-11"
                >سعر الشحنة</label
              >
            </div>

            <div class="pb-2"></div>
          </div>
          <button
            @click="showprice()"
            class="mx-3 mb-3 btn btn-full gradient-green shadow-bg shadow-bg-s"
          >
            تاكيد الطلب
          </button>
        </div>
      </div>
      <a
        href="#"
        v-show="false"
        ref="mybtr15"
        class="list-group-item"
        data-bs-toggle="offcanvas"
        data-bs-target="#menu-transfer-ok12"
        >asd
      </a>
      <div
        id="menu-transfer-ok12"
        style="width: 320px; display: block; visibility: visible"
        class="offcanvas offcanvas-modal offcanvas-detached rounded-m"
      >
        <div class="content text-center">
          <i
            style="font-size: 65px"
            :class="
              'scale-box  ' +
              msgicon +
              ' color-' +
              msgiconcolor +
              '-dark shadow-s rounded-circle p-0 mt-3 mb-3 d-inline-block'
            "
          ></i>
          <h1 class="pt-3 font-18">{{ msg }}</h1>
          <a
            href="#"
            data-bs-toggle="offcanvas"
            :class="
              'btn btn-full gradient-' +
              msgiconcolor +
              ' shadow-bg shadow-bg-xs'
            "
            >موافق</a
          >
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage } from "@ionic/vue";
import { defineComponent } from "vue";
import { instance } from "./../../modules/instance";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { mapState } from "vuex";

export default defineComponent({
  computed: {
    ...mapState(["notification"]),
  },
  ionViewWillEnter() {
    this.getca();
    this.getadress();
    this.getadressfi();
    this.getcity();
    this.getbanner();
  },
  name: "HomePage",
  components: {
    IonContent,
    IonPage,
  },

  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      storedatacol: {
        name: { required },
        phone: { required },
        city: { required },
        num: { required },
      },
      storedatafi: {
        name: { required },
        phone: { required },
        city: { required },
        value: { required },
      },
      culculate: {
        from: { required },
        to: { required },
        price: { required },
      },
    };
  },

  data() {
    return {
      all: "0",
      done: "0",
      field: "0",
      waiting: "0",

      // collection data
      storedatacol: {
        adress: "",
        note: "",
        state: "",
        city: "",
        location: "",
        name: "",
        phone: "",
        num: "0",
        realnum: "",
        duedate: "",
        driverid: "",
      },
      collectionnotes: {
        price: "0",
        driver_price: "0",
        company_price: "0",
        note: "0",
        duration: "0",
        morethen: "0",
        freemorethen: "0",
      },
      colisLoading: false,
      storedatafi: {
        adress: "",
        note: "",
        state: "",
        city: "",
        location: "",
        name: "",
        phone: "",
        value: "",
        realvalue: "",
        valuebefore: "",
        valueafter: "",
        invoicenum: "",
        duedate: "",
        driverid: "",
        num: "",
      },

      culculate: {
        from: "",
        to: "",
        price: "0",
      },

      adresses: [],
      from: [],
      to: [],

      banner: [],

      msg: "",
      msgicon: "",
      msgiconcolor: "",
    };
  },

  methods: {
    async getbanner() {
      this.banner = [];
      const res = await instance.post("/get-banner");
      if (res.status == 200) {
        this.banner = res.data;
      } else {
        console.log(res);
      }
    },

    async showprice() {
      const result = await this.v$.culculate.$validate();
      if (!result) {
        // notify user form is invalid
        return;
      }
      const res = await instance.post("/home-price-get", this.culculate);
      if (res.status == 200) {
        this.$router.push({
          path: "/culculater",
          query: {
            from: res.data.from,
            to: res.data.to,
            duration: res.data.duration,
            value: this.culculate.price,
            totel: res.data.totel,
            price: res.data.price,
            commition: res.data.commition,
            rcommition: res.data.rcommition,
            size: res.data.size,
          },
        });
      } else {
        console.log(res);
      }
    },

    async getnoti() {
      try {
        this.$store.commit("notification/getnoti");
      } catch (error) {
        return "error";
      }
    },
    async getca() {
      const res = await instance.get("/get-reports-home");
      if (res.status == 200) {
        this.all = res.data.all;
        this.done = res.data.done;
        this.field = res.data.field;
        this.waiting = res.data.waiting;
      } else {
        console.log("sad");
      }
    },
    async savecollection(e) {
      this.colisLoading = true;
      e.preventDefault();

      const result = await this.v$.storedatacol.$validate();
      if (!result) {
        // notify user form is invalid
        return;
      }
      const res = await instance.post("/makerequest", this.storedatacol);
      if (res.status == 200) {
        if (res.data.msg == "done") {
          this.msg = res.data.msgc;
          this.msgicon = "bi bi-check-circle-fill";
          this.msgiconcolor = "green";
        } else if (res.data.msg == "hasactive") {
          this.msg = res.data.msgc;
          this.msgicon = "bi bi-x-circle-fill";
          this.msgiconcolor = "red";
        }
        this.$refs.hidesho.click();
        setTimeout(() => {
          this.$refs.mybtr15.click();
          this.colisLoading = false;
        }, 400);
      } else {
        console.log(res);
      }
    },
    async savefi(e) {
      e.preventDefault();
      const result = await this.v$.storedatafi.$validate();
      if (!result) {
        // notify user form is invalid
        return;
      }
      const res = await instance.post("/makerequest-finance", this.storedatafi);
      if (res.status == 200) {
        if (res.data.msg == "done") {
          this.msg = res.data.msgc;
          this.msgicon = "bi bi-check-circle-fill";
          this.msgiconcolor = "green";
        } else if (res.data.msg == "hasactive") {
          this.msg = res.data.msgc;
          this.msgicon = "bi bi-x-circle-fill";
          this.msgiconcolor = "red";
        }
        this.$refs.hidesho.click();
        setTimeout(() => {
          this.$refs.mybtr15.click();
        }, 400);
      } else {
        console.log(res);
      }
    },
    async getadress() {
      this.adresses = [];
      const res = await instance.get("/get-req-data");
      if (res.status == 200) {
        this.adresses = res.data.adress;
        this.storedatacol.name = res.data.agents.name;
        this.storedatacol.phone = res.data.agents.shopphone;
        this.collectionnotes = res.data.city;
        setTimeout(() => {
          if (this.adresses.length > 0) {
            this.storedatacol.city = this.adresses.find(
              (x) => x.isdefult === "1"
            ).id;
          }
        }, 1000);

        this.storedatacol.num = res.data.pcount;
      } else {
        console.log(res);
      }
    },
    async getcity() {
      const res = await instance.get("/home-data-city");
      if (res.status == 200) {
        this.from = res.data.from;
        this.to = res.data.to;

        if (this.from.length > 0) {
          this.culculate.from = this.from.find((x) => x.isdefult === "1").id;
        }
        if (this.to.length > 0) {
          this.culculate.to = this.to.find((x) => x.isdefult === "1").id;
        }
      } else {
        console.log(res);
      }
    },
    async getadressfi() {
      const res = await instance.get("/get-req-data-finance");
      if (res.status == 200) {
        this.storedatafi.name = res.data.agents.name;
        this.storedatafi.phone = res.data.agents.shopphone;

        setTimeout(() => {
          if (this.adresses.length > 0) {
            this.storedatafi.city = this.adresses.find(
              (x) => x.isdefult === "1"
            ).id;
          }
        }, 1000);

        this.storedatafi.value = res.data.psum;
        this.storedatafi.num = res.data.pcount;
      } else {
        console.log(res);
      }
    },
  },
});
</script>
