<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div class="card" data-card-height="cover" style="height: 812px">
        <div class="card-center">
          <div class="content text-center mb-0">
            <i
              class="bi bi-arrow-repeat font-500 color-highlight mb-5"
              style="font-size: 130px"
            ></i>
            <h1 class="font-30 bt-5" style="margin-top: 25px">
              تم تطوير النظام
            </h1>
            <p class="text-center mb-5">
              قم بتحديق للتمع بجيع المزاية في التطبيق
            </p>

            <div class="text-center">
              
              <a href="#" class="icon icon-s bg-facebook rounded-sm"
                ><i class="bi bi-facebook"></i
              ></a>
              <a href="#" class="icon icon-s mx-2 bg-instagram rounded-sm"
                ><i class="bi bi-info-lg"></i
              ></a>
              <a href="#" class="icon icon-s bg-facebook rounded-sm"
                >تحديث</a>

              <p class="font-12 pt-2">
                لي اي استفيار التواصل معي الدعم الفني او علي مواقع التواصل
                الاجتماعي
              </p>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage } from "@ionic/vue";
import { defineComponent } from "vue";
import { mapState } from "vuex";

export default defineComponent({
  created() {
    console.log(this.auth.firsttime);
  },
  name: "SplashPage",
  computed: {
    ...mapState(["auth"]),
  },
  components: {
    IonContent,
    IonPage,
  },
});
</script>
s
