<template>
  <ion-page>
    <div id="page">
      <div id="footer-bar" class="footer-bar-1 footer-bar-detached">
        <router-link to="/"
          ><i class="bi bi-house"></i><span>الرئيسية</span></router-link
        >
        <router-link to="/shipments"
          ><i class="bi bi-box"></i><span>شحنات</span></router-link
        >
        <router-link to="/add-shipments" class="circle-nav-2"
          ><i class="bi bi-plus-circle"></i><span>اضافة</span></router-link
        >
        <router-link to="reports"
          ><i class="bi bi-graph-up"></i><span>احصائيات</span></router-link
        >
        <a href="#" data-bs-toggle="offcanvas" data-bs-target="#menu-sidebar"
          ><i class="bi bi-three-dots"></i><span>اعدادات</span></a
        >
      </div>

      <div class="page-content footer-clear">
        <ion-router-outlet></ion-router-outlet>
      </div>
      <div
        id="menu-notifications"
        data-menu-load="menu-notifications.html"
        class="offcanvas offcanvas-top offcanvas-detached rounded-m"
        style="display: block; visibility: visible"
        aria-modal="false"
        role="dialog"
      >
        <div class="menu-size">
          <div class="d-flex mx-3 mt-3 py-1">
            <div class="align-self-center">
              <h1 class="mb-0">الاشعارات</h1>
            </div>
            <div class="align-self-center ms-auto">
              <a
                href="#"
                ref="closenoti"
                class="py-3 ps-4 shadow-0 me-n2"
                data-bs-dismiss="offcanvas"
              >
                <i class="bi bi-x color-red-dark font-26"></i>
              </a>
            </div>
          </div>
          <div class="content pb-2">
            <a
              :style="
                d.isreaded == 0
                  ? 'background-color: rgba(210, 210, 210, 0.8);'
                  : ''
              "
              class="d-flex py-1 mb-1"
              v-for="(d, i) in notification.data"
              :key="i"
              @click="readnoti(d.id, d.notitypy, d.value)"
            >
              <div class="align-self-center">
                <span
                  :class="
                    'icon rounded-s me-2  shadow-bg shadow-bg-xs gradient-' +
                    d.color
                  "
                  ><i :class="'bi bi-' + d.icon + ' color-white'"></i
                ></span>
              </div>
              <div class="align-self-center ps-1">
                <h5 class="pt-1 mb-n1">{{ d.title }}</h5>
                <p class="mb-0 font-11 opacity-70">{{ d.content }}</p>
              </div>
              <div class="align-self-center ms-auto text-end">
                <h4 class="pt-1 mb-n1 color-red-dark"></h4>
                <p class="mb-0 font-11">{{ formatDate(d.created_at) }}</p>
              </div>
            </a>
            <p class="h6 text-center" v-if="notification.txt">
              لا يوجد اشعارات
            </p>
            <div class="text-center" v-if="notification.spinner">
              <div class="spinner-grow text-danger" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
          <router-link
            to="/notification-page"
            @click="hidenotimodel()"
            class="mx-3 btn btn-full gradient-highlight shadow-bg shadow-bg-s mb-3"
            >عرض الكل</router-link
          >
          <a
            v-show="false"
            href="#"
            ref="hidenotimodel"
            data-bs-dismiss="offcanvas"
            class="btn btn-full gradient-highlight shadow-bg shadow-bg-s mt-4"
            >Apply Settings</a
          >
        </div>
      </div>
      <a
        v-show="false"
        ref="logoutModalbtn"
        href="#"
        data-backdrop="static"
        data-keyboard="false"
        class="list-group-item"
        data-bs-toggle="offcanvas"
        data-bs-target="#menu-logout-msg"
        ><i class="bi bi-three-dots"></i><span>اعدادات</span></a
      >
      <div
        ref="logoutModal"
        id="menu-logout-msg"
        style="width: 320px; display: block; visibility: visible"
        class="offcanvas offcanvas-modal offcanvas-detached rounded-m"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div class="content text-center">
          <i
            style="font-size: 65px"
            class="scale-box bi bi-door-open color-red-dark shadow-s rounded-circle p-0 mt-3 mb-3 d-inline-block"
          ></i>
          <!-- <h1 class="pt-3 font-28">معلومات الدخول غير صحيحة</h1> -->
          <h1 class="font-14 mb-3">
            هل انت متاكد من تسجيل الخروج
          </h1>

          <a
            @click="logoutconform()"
            data-bs-toggle="offcanvas"
            class="btn btn-full gradient-red shadow-bg shadow-bg-xs"
            >تاكيد الخروج</a
          >
        </div>
      </div>

      <div
        id="menu-sidebar"
        data-menu-active="nav-welcome"
        class="offcanvas offcanvas-start offcanvas-detached rounded-m"
        style="display: block"
      >
        <div class="menu-size" style="width: 230px">
          <div class="pt-3">
            <div class="page-title sidebar-title d-flex">
              <div class="align-self-center me-auto">
                <p class="color-highlight">مرحبا بك</p>
                <h3>{{ user.shopname }}</h3>
              </div>
              <div class="align-self-center ms-auto">
                <a class="icon gradient-blue shadow-bg shadow-bg-s rounded-m">
                  <img
                    v-if="user.personalid == ''"
                    src="../assets/images/avatar-1.png"
                    class="rounded-m"
                    alt="img"
                    width="45"
                  />
                  <img
                    v-if="user.personalid != ''"
                    :src="user.personalid"
                    class="rounded-m"
                    alt="img"
                    width="45"
                  />
                </a>

                <div class="dropdown-menu">
                  <div class="card card-style shadow-m mt-1 me-1">
                    <div
                      class="list-group list-custom list-group-s list-group-flush rounded-xs px-3 py-1"
                    >
                      <a href="#" class="list-group-item">
                        <i
                          class="has-bg gradient-green shadow-bg shadow-bg-xs color-white rounded-xs bi bi-credit-card"
                        ></i>
                        <strong class="font-13">Wallet</strong>
                      </a>
                      <a href="#" class="list-group-item">
                        <i
                          class="has-bg gradient-blue shadow-bg shadow-bg-xs color-white rounded-xs bi bi-graph-up"
                        ></i>
                        <strong class="font-13">Activity</strong>
                      </a>
                      <a href="#" class="list-group-item">
                        <i
                          class="has-bg gradient-yellow shadow-bg shadow-bg-xs color-white rounded-xs bi bi-person-circle"
                        ></i>
                        <strong class="font-13">Account</strong>
                      </a>
                      <a href="#" class="list-group-item">
                        <i
                          class="has-bg gradient-red shadow-bg shadow-bg-xs color-white rounded-xs bi bi-power"
                        ></i>
                        <strong class="font-13">Log Out</strong>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="divider divider-margins mb-3 opacity-50"></div>

            <div class="list-group list-custom list-menu-large">
              <router-link
                to="/account"
                id="nav-welcome"
                class="list-group-item list-group-item-active"
              >
                <i
                  class="bi bg-red-dark bi-person-badge"
                ></i>
                <div>حسابي</div>
                <i class="bi bi-chevron-right"></i>
              </router-link>
              <router-link
                to="/requests"
                id="nav-pages"
                class="list-group-item"
              >
                <i
                  class="bi bg-yellow-dark  bi-list-task" 
                ></i>
                <div>طلبات</div>
                <i class="bi bi-chevron-right"></i>
              </router-link>
              <router-link
                to="/finance-page"
                id="nav-pages"
                class="list-group-item"
              >
                <i
                  class="bi   bi-cash-coin" style="background-color:#1abc9c ;color: white;"
                ></i>
                <div>المالية</div>
                <i class="bi bi-chevron-right"></i>
              </router-link>
              <!-- <a id="nav-pages" class="list-group-item">
                <i
                  class="bi bg-green-dark shadow-bg shadow-bg-xs bi-shop-window"
                ></i>
                <div>المخزن</div>
                <i class="bi bi-chevron-right"></i>
              </a> -->
              <router-link
                to="/g-reports"
                id="nav-pages"
                class="list-group-item"
              >
                <i
                  class="bi bg-blue-dark  bi-pie-chart"
                ></i>
                <div>تقارير</div>
                <i class="bi bi-chevron-right"></i>
              </router-link>

              <a
                href="#"
                class="list-group-item"
                data-toggle-theme=""
                data-trigger-switch="switch-1"
              >
                <i
                  class="bi bg-highlight   bi-lightbulb-fill"
                ></i>
                <div>Dark Mode</div>
                <div class="form-switch ios-switch switch-green switch-s me-2">
                  <input
                    type="checkbox"
                    data-toggle-theme=""
                    :checked="hasclass"
                    @change="darkmode()"
                    v-model="hasclass"
                    class="ios-input"
                    id="switch-1"
                  />
                  <label class="custom-control-label" for="switch-1"></label>
                </div>
              </a>
            </div>

            <div class="divider divider-margins opacity-50"></div>

            <div class="list-group list-custom list-menu-small">
              <!-- <router-link to="/chat" class="list-group-item default-link">
                <i class="bi-question-circle opacity-20 font-16"></i>
                <div>الدعم الفني</div>
                <i class="bi bi-chevron-right"></i>
              </router-link> -->
              <router-link to="/terms" class="list-group-item">
                <i class="bi bi-shield-exclamation opacity-20 font-16"></i>
                <div>شروط الاستخدام</div>
                <i class="bi bi-chevron-right"></i>
              </router-link>
              <!-- <a class="list-group-item">
                <i class="bi bi-gear-wide-connected opacity-20 font-16"></i>
                <div>اعدادات</div>
                <i class="bi bi-chevron-right"></i>
              </a> -->
              <router-link to="/about-page" class="list-group-item">
                <i class="bi bi-info-circle-fill opacity-20 font-16"></i>
                <div>عن الشركة</div>
                <i class="bi bi-chevron-right"></i>
              </router-link>
              <a @click="logout()" class="list-group-item">
                <i class="bi bi-door-closed opacity-20 font-16"></i>
                <div>تسجيل الخروج</div>
                <i class="bi bi-chevron-right"></i>
              </a>
            </div>
            <div class="divider divider-margins opacity-50"></div>

            <p class="px-3 font-9 opacity-30 color-theme mt-n3">
              Copyright <span class="copyright-year">2022</span>.
            </p>
          </div>
        </div>
      </div>
    </div>
  </ion-page>
</template>

<script>
import { logout } from "./../modules/helpers";
import { IonPage, IonRouterOutlet } from "@ionic/vue";
import { mapState } from "vuex";
import { instance } from "./../modules/instance";

export default {
  props: {},
  computed: {
    ...mapState(["user", "notification"]),
  },
  created() {
    this.getedata();
    this.checkcalss();
    this.getnoti();
  },
  data() {
    return {
      home: "active",
      col: "",
      hasclass: false,
      // notification
      notifications: [],
      txt: true,
      spinner: false,
    };
  },
  name: "defPge",
  components: {
    IonPage,
    IonRouterOutlet,
  },
  methods: {
    logout() {
      this.$refs.logoutModalbtn.click();
    },
    logoutconform() {
      logout();
    },
    async getnoti() {
      try {
        this.$store.commit("notification/getnoti");
      } catch (error) {
        return "error";
      }
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },

    hidenotimodel() {
      this.$refs.hidenotimodel.click();
    },

    async readnoti(id, type, value) {
      try {
        this.$store.commit("notification/readnoti", id);

        this.$refs.closenoti.click();
        if (
          type == "faildpack" ||
          type == "packnote" ||
          type == "newpack" ||
          type == "updatepack" ||
          type == "cancel" ||
          type == "respack" ||
          type == "respartpack" ||
          type == "addtoship"
        ) {
          this.hidenotimodel();
          this.$router.push({
            path: "/shipments-page",
            query: {
              package: value,
            },
          });
        }
      } catch (error) {
        return "error";
      }
    },

    async getnotification() {
      try {
        this.spinner = true;
        this.notifications = [];
        const res = await instance.get("/notifucaions");

        if (res.status === 200) {
          this.notifications = res.data;
          if (this.notifications.leanth > 0) {
            this.txt = false;
            this.spinner = false;
          } else {
            this.txt = true;
            this.spinner = false;
          }
        } else {
          return "error";
        }
      } catch (error) {
        return "error";
      }
    },

    async getedata() {
      try {
        const res = await instance.post("/me");

        if (res.status === 200) {
          this.$store.commit("user/updateemp", res.data.data);
        } else {
          return "error";
        }
      } catch (error) {
        return "error";
      }
    },
    darkmode() {
      let bodyEl = document.querySelector("body");

      if (this.hasclass == true) {
        bodyEl.classList.remove("theme-light");
        localStorage.setItem("Colormode", "theme-dark");
        bodyEl.classList.add("theme-dark");
      } else {
        bodyEl.classList.remove("theme-dark");
        localStorage.setItem("Colormode", "theme-light");
        bodyEl.classList.add("theme-light");
      }
      this.checkcalss();
    },
    checkcalss() {
      const v = document.body.classList.contains("theme-dark");
      this.hasclass = v;
    },
  },
};
</script>
