import { checkworkingstatus } from "../../modules/helpers";

export default function guest({ next  , store }: any) {
  
  if (store.getters['auth/loggedIn'] && !store.getters['auth/morup']) {
    return next({
      path: "/",
    });
  }
  checkworkingstatus();

  return next();
}
