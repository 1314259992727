<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div class="card" data-card-height="cover" style="height: 812px">
        <div class="card-center">
          <div class="content text-center mb-0">
            <i
              class="bi bi-gear-wide-connected font-500 color-highlight mb-5"
              style="font-size: 130px"
            ></i>
            <h1 class="font-30 bt-5" style="margin-top: 25px">
              تحت الصيانة 
            </h1>
            <p class="text-center mb-5">سنعود في اقرب وقت شكرا لك على صبرك.</p>
            <div class="text-center">
              <a href="https://www.facebook.com/Lnadcut" target="_blank" class="icon icon-s bg-facebook rounded-sm"
                ><i class="bi bi-facebook"></i
              ></a>
              <a href="#" class="icon icon-s mx-2 bg-instagram rounded-sm"
                ><i class="bi bi-info-lg"></i
              ></a>

              <p class="font-12 pt-2">
                لي اي استفيار التواصل معي الدعم الفني او علي مواقع التواصل
                الاجتماعي
              </p>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage } from "@ionic/vue";
import { defineComponent } from "vue";
import { mapState } from "vuex";
import { instance } from "./../../modules/instance";

export default defineComponent({
  created() {
   this.gethistory()
  },
  name: "SplashPage",
  computed: {
    ...mapState(["auth"]),
  },
  data() {
    return {
      code: 'error',
    };
  },
  methods: {
    async gethistory() {
      instance.get('/check-status').then((response) => {
        this.code = response.data.status
  }).catch((err ) => {
    console.log('err')
this.code = err.message
  })
  }
},
  components: {
    IonContent,
    IonPage,
  },
});
</script>

