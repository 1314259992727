<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div id="page">
        <div class="page-content" style="padding-bottom: 0px">
          <div class="pt-3">
            <div class="page-title d-flex">
              <div class="align-self-center me-auto">
                <img
                  class="preload-img img-fluid ps-3 pe-3"
                  src="https://landcut.ly/images/landcut-white.png"
                />
              </div>
            </div>
          </div>
          <svg
            id="header-deco"
            viewBox="0 0 1440 600"
            xmlns="http://www.w3.org/2000/svg"
            class="transition duration-300 ease-in-out delay-150"
          >
            <path
              id="header-deco-1"
              d="M 0,600 C 0,600 0,120 0,120 C 92.36363636363635,133.79904306220095 184.7272727272727,147.59808612440193 287,148 C 389.2727272727273,148.40191387559807 501.4545454545455,135.40669856459328 592,129 C 682.5454545454545,122.5933014354067 751.4545454545455,122.77511961722489 848,115 C 944.5454545454545,107.22488038277511 1068.7272727272727,91.49282296650718 1172,91 C 1275.2727272727273,90.50717703349282 1357.6363636363635,105.25358851674642 1440,120 C 1440,120 1440,600 1440,600 Z"
            ></path>
            <path
              id="header-deco-2"
              d="M 0,600 C 0,600 0,240 0,240 C 98.97607655502392,258.2105263157895 197.95215311004785,276.4210526315789 278,282 C 358.04784688995215,287.5789473684211 419.16746411483257,280.5263157894737 524,265 C 628.8325358851674,249.4736842105263 777.377990430622,225.47368421052633 888,211 C 998.622009569378,196.52631578947367 1071.3205741626793,191.57894736842107 1157,198 C 1242.6794258373207,204.42105263157893 1341.3397129186603,222.21052631578948 1440,240 C 1440,240 1440,600 1440,600 Z"
            ></path>
            <path
              id="header-deco-3"
              d="M 0,600 C 0,600 0,360 0,360 C 65.43540669856458,339.55023923444975 130.87081339712915,319.1004784688995 245,321 C 359.12918660287085,322.8995215311005 521.9521531100479,347.1483253588517 616,352 C 710.0478468899521,356.8516746411483 735.3205741626795,342.3062200956938 822,333 C 908.6794258373205,323.6937799043062 1056.7655502392345,319.62679425837325 1170,325 C 1283.2344497607655,330.37320574162675 1361.6172248803828,345.1866028708134 1440,360 C 1440,360 1440,600 1440,600 Z"
            ></path>
            <path
              id="header-deco-4"
              d="M 0,600 C 0,600 0,480 0,480 C 70.90909090909093,494.91866028708137 141.81818181818187,509.8373205741627 239,499 C 336.18181818181813,488.1626794258373 459.6363636363636,451.5693779904306 567,446 C 674.3636363636364,440.4306220095694 765.6363636363636,465.88516746411483 862,465 C 958.3636363636364,464.11483253588517 1059.8181818181818,436.8899521531101 1157,435 C 1254.1818181818182,433.1100478468899 1347.090909090909,456.555023923445 1440,480 C 1440,480 1440,600 1440,600 Z"
            ></path>
          </svg>
          <div class="card card-style">
            <div class="content">
              <form @submit="SiunupUser" enctype="multipart/form-data">
                <h1 class="mb-0 pt-2 mb-3">انشاء حساب</h1>
                <p>البيانات الشخصية :-</p>
                <div
                  class="form-custom form-label form-border form-icon mb-3 bg-transparent"
                >
                  <i class="bi bi-person font-13"></i>
                  <input
                    v-model="data.name"
                    type="text"
                    class="form-control rounded-xs"
                    :class="{
                      'is-invalid': v$.data.name.$error,
                    }"
                    id="c1"
                    placeholder="الاسم الكامل"
                  />
                  <label for="c1" class="color-theme">الاسم الكامل</label>
                  <span>(مطلوب)</span>
                </div>
                <div
                  class="form-custom form-label form-border form-icon mb-3 bg-transparent"
                >
                  <i class="bi bi-envelope font-13"></i>
                  <input
                    v-model="data.email"
                    type="email"
                    class="form-control rounded-xs"
                    :class="{
                      'is-invalid': v$.data.email.$error,
                    }"
                    id="c1"
                    placeholder="البريد الالكتروني"
                  />
                  <label for="c1" class="color-theme">البريد الالكتروني</label>
                  <span>(مطلوب)</span>
                </div>
                <div
                  class="form-custom form-label form-border form-icon mb-3 bg-transparent"
                >
                  <i class="bi bi-phone font-13"></i>
                  <input
                    v-model="data.phone[0].phone"
                    type="number"
                    class="form-control rounded-xs"
                    id="c1"
                    placeholder="الهاتف"
                  />
                  <label for="c1" class="color-theme">الهاتف</label>
                  <span>(مطلوب)</span>
                </div>
                <div
                  class="form-custom form-label form-border form-icon mb-3 bg-transparent"
                >
                  <i class="bi bi-geo-alt font-13"></i>
                  <input
                    v-model="data.adress"
                    type="text"
                    class="form-control rounded-xs"
                    id="c1"
                    placeholder="العنوان"
                  />
                  <label for="c1" class="color-theme">العنوان</label>
                </div>
                <div
                  class="form-custom form-label form-border form-icon mb-3 bg-transparent"
                >
                  <i class="bi bi-gender-ambiguous font-13"></i>

                  <select
                    v-model="data.sex"
                    class="form-select rounded-xs"
                    id="c6a"
                  >
                    <option value="male">ذكر</option>
                    <option value="female">انثي</option>
                  </select>
                  <label for="c1" class="color-theme">الجنس</label>
                </div>

                <p>بيانات النشاط التجاري :-</p>

                <div class="row">
                  <div class="col-auto" style="padding-top: 13px">
                    <div class="file-data pb-5">
                      <input
                        type="file"
                        id="file-upload"
                        @change="onFileChange"
                        class="upload-file bg-highlight shadow-s rounded-s"
                        accept="image/*"
                      />
                      <p class="upload-file-text">صورة الحساب</p>
                    </div>
                  </div>
                  <div class="col-auto">
                    <div class="align-self-center ms-auto">
                      <a
                        class="icon gradient-blue shadow-bg shadow-bg-s rounded-m"
                      >
                        <img
                          :src="url"
                          class="rounded-m"
                          alt="img"
                          width="45"
                        />
                      </a>
                    </div>
                  </div>
                </div>

                <div
                  class="form-custom form-label form-border form-icon mb-3 bg-transparent"
                >
                  <i class="bi bi-shop font-13"></i>
                  <input
                    v-model="data.shopname"
                    :class="{
                      'is-invalid': v$.data.shopname.$error,
                    }"
                    type="text"
                    class="form-control rounded-xs"
                    id="c1"
                    placeholder="اسم النشاط"
                  />
                  <label for="c1" class="color-theme">اسم النشاط</label>
                </div>
                <div
                  class="form-custom form-label form-border form-icon mb-3 bg-transparent"
                >
                  <i class="bi bi-blockquote-left font-13"></i>

                  <select
                    v-model="data.shoptype"
                    class="form-select rounded-xs"
                    id="c6a"
                    :class="{
                      'is-invalid': v$.data.shoptype.$error,
                    }"
                  >
                    <option value="">قم باختيار النشاط</option>
                    <option v-for="(d, i) in ba" :key="i" :value="d.id">
                      {{ d.name }}
                    </option>
                  </select>
                  <label for="c1" class="color-theme">نوع التشاط</label>
                </div>

                <div
                  class="form-custom form-label form-border form-icon mb-3 bg-transparent"
                >
                  <i class="bi bi-phone font-13"></i>
                  <input
                    v-model="data.shopphone"
                    :class="{
                      'is-invalid': v$.data.shopphone.$error,
                    }"
                    type="text"
                    class="form-control rounded-xs"
                    id="c1"
                    placeholder="هاتف النشاط"
                  />
                  <label for="c1" class="color-theme">هاتف النشاط</label>
                  <span>(مطلوب)</span>
                </div>
                <div
                  class="form-custom form-label form-border form-icon mb-3 bg-transparent"
                >
                  <i class="bi bi-link-45deg font-13"></i>
                  <input
                    v-model="data.link"
                    type="text"
                    class="form-control rounded-xs"
                    id="c1"
                    placeholder="رابط الالكتروني"
                  />
                  <label for="c1" class="color-theme">رابط الالكتروني</label>
                  <span></span>
                </div>

                <p>بيانات الحماية :-</p>

                <div
                  class="form-custom form-label form-border form-icon mb-4 bg-transparent"
                >
                  <i class="bi bi-lock font-13"></i>
                  <input
                    v-model="data.password"
                    :class="{
                      'is-invalid': v$.data.password.$error,
                    }"
                    type="password"
                    class="form-control rounded-xs"
                    id="c2"
                    placeholder="كلمة المرور"
                  />
                  <label for="c2" class="color-theme">كلمة المرور</label>
                  <span>(مطلوب)</span>
                </div>

                <div
                  class="form-custom form-label form-border form-icon mb-4 bg-transparent"
                >
                  <i class="bi bi-lock font-13"></i>
                  <input
                    v-model="data.password_confirmation"
                    :class="{
                      'is-invalid': v$.data.password_confirmation.$error,
                    }"
                    type="password"
                    class="form-control rounded-xs"
                    id="c2"
                    placeholder=" تاكيد كلمة المرور"
                  />
                  <label for="c2" class="color-theme"> تاكيد كلمة المرور</label>
                  <span>(مطلوب)</span>
                </div>
                <div class="form-check form-check-custom my-4">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="c2a2"
                    v-model="data.terms"
                  />
                  <label
                    class="form-check-label"
                    for="c2a2"
                    @click="showterms()"
                    >شروط و احكام الخدمة
                    <span v-if="v$.data.terms.$error" class="color-red-dark"
                      >(مطلوب)</span
                    ></label
                  >
                  <i class="is-checked color-green-dark bi bi-check-square"></i>
                  <i class="is-unchecked color-red-dark bi bi-x-square"></i>
                </div>

                <button
                  v-if="!isLoading"
                  type="submit"
                  class="btn btn-full gradient-highlight shadow-bg shadow-bg-xs"
                >
                  انشاء حساب
                </button>
                <button
                  v-if="isLoading"
                  disabled
                  type="submit"
                  class="btn btn-full gradient-highlight shadow-bg shadow-bg-xs"
                >
                  الرجاء الانتظار
                </button>
                <a
                  v-show="false"
                  ref="errorbtn"
                  href="#"
                  data-backdrop="static"
                  data-keyboard="false"
                  class="list-group-item"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#menu-failed-msg"
                  ><i class="bi bi-three-dots"></i><span>اعدادات</span></a
                >
                <div class="text-center mb-3 mt-3">
                  <router-link to="/login" class="font-12"
                    >لديك حساب ؟ قم بتسجيل الدخول</router-link
                  >
                </div>
              </form>
              <div
                ref="myModal"
                id="menu-failed-msg"
                style="width: 320px; display: block; visibility: visible"
                class="offcanvas offcanvas-modal offcanvas-detached rounded-m"
                data-backdrop="static"
                data-keyboard="false"
              >
                <div class="content text-center">
                  <i
                    style="font-size: 65px"
                    class="scale-box bi bi-x-circle-fill color-red-dark shadow-s rounded-circle p-0 mt-3 mb-3 d-inline-block"
                  ></i>
                  <!-- <h1 class="pt-3 font-28">معلومات الدخول غير صحيحة</h1> -->
                  <p class="font-14 mb-3">
                    {{ errormessage }}
                  </p>

                  <a
                    href="#"
                    data-bs-toggle="offcanvas"
                    class="btn btn-full gradient-red shadow-bg shadow-bg-xs"
                    >اغلاق</a
                  >
                </div>
              </div>
              <a
                ref="termsbtn"
                v-show="false"
                data-bs-toggle="offcanvas"
                data-bs-target="#terms-msg"
                class="icon icon-xxl rounded-m bg-theme shadow-m"
                ><i class="font-28 color-red-dark bi bi-arrow-down-circle"></i
              ></a>
              <div
                ref="terms"
                id="terms-msg"
                style="width: 320px; display: block"
                class="offcanvas offcanvas-modal offcanvas-detached rounded-m"
                data-backdrop="static"
                data-keyboard="false"
              >
                <div class="content text-center">
                  <div class="pt-3">
                    <div
                      class="card card-style"
                      style="max-height: 350px; overflow-y: auto"
                    >
                      <div class="content" v-html="rermsofuse"></div>
                    </div>
                  </div>

                  <a
                    href="#"
                    data-bs-toggle="offcanvas"
                    class="btn btn-full gradient-red shadow-bg shadow-bg-xs"
                    >اغلاق</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, alertController } from "@ionic/vue";
import { defineComponent } from "vue";
import { instance } from "./../../modules/instance";
import { useVuelidate } from "@vuelidate/core";
import { required, email, minLength, sameAs } from "@vuelidate/validators";

export default defineComponent({
  created() {
    this.getba();
  },
  name: "signupPage",
  components: {
    IonContent,
    IonPage,
  },
  setup() {
    const presentAlert = async () => {
      const alert = await alertController.create({
        header: "تنبيه.!",
        subHeader: "معلومات الدخول غير صحيحة",
        message: "",
        buttons: ["OK"],
      });

      await alert.present();
    };

    return { presentAlert, v$: useVuelidate() };
  },
  validations() {
    return {
      data: {
        name: { required },
        email: { required, email },
        shopname: { required },
        shopphone: { required, minLength: minLength(10) },
        shoptype: { required },
        password: { required, minLength: minLength(8) },
        password_confirmation: {
          sameAsPassword: sameAs(this.data.password),
        },
        terms: {
          sameAsRawValue: sameAs(true),
        },
      },
    };
  },
  data() {
    return {
      data: {
        name: "",
        email: "",
        phone: [{ phone: "" }],
        adress: "",
        sex: "male",
        personalid: "",
        allow: "true",
        password: "",
        password_confirmation: "",
        state: "1",
        shopname: "",
        shopphone: "",
        shoptype: "",
        link: "",
        terms: "",
      },
      ba: [],
      isLoading: false,
      needResendOtpCode: false,
      url: "@/assets/images/avatar-1.png",
      errormessage: "",

      rermsofuse: [],
    };
  },

  methods: {
    async getca() {
      const res = await instance.get("/get-CoumpanyInfom");
      if (res.status == 200) {
        this.rermsofuse = res.data.polices;
      } else {
        console.log("sad");
      }
    },

    showterms() {
      this.getca();
      this.$refs.termsbtn.click();
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.data.personalid = e.target.files[0];
      this.url = URL.createObjectURL(file);
    },
    async SiunupUser(e) {
      e.preventDefault();

      const result = await this.v$.$validate();
      if (!result) {
        // notify user form is invalid
        return;
      }

      this.isLoading = true;

      let formData = new FormData();
      formData.append("name", this.data.name);
      formData.append("email", this.data.email);
      formData.append("adress", this.data.adress);
      formData.append("sex", this.data.sex);
      formData.append("state", this.data.state);
      formData.append("allow", this.data.allow);
      formData.append("personalid", this.data.personalid);
      formData.append("shopphone", this.data.shopphone);
      formData.append("shopname", this.data.shopname);
      formData.append("shoptype", this.data.shoptype);
      formData.append("password", this.data.password);
      formData.append("link", this.data.link);

      for (let i = 0; i < this.data.phone.length; i++) {
        let c = this.data.phone[i].phone;
        formData.append("phone[" + i + "]", c);
      }

      instance
        .post("/signup", formData)
        .then(function () {
          this.$router.replace({
            path: "/login",
          });
        })
        .catch((error) => {
          if (error.response) {
            this.errormessage = error.response.data.data;
            this.isLoading = false;
            this.$refs.errorbtn.click();
          } else {
            this.$router.replace({
              path: "/login",
            });
          }
        });
    },
    async getba() {
      const res = await instance.get("/agents-types");
      if (res.status == 200) {
        this.ba = res.data;
      } else {
        console.log(res);
      }
    },
  },
});
</script>
