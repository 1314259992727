import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import { IonicVue } from "@ionic/vue";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

import "bootstrap-vue-3/dist/bootstrap-vue-3.css";

// /* Theme variables */
// import './theme/variables.css';

// theme css
import "./theme/bootstrap.css";
import "./theme/highlights/orange.css";
import "./assets/fonts/bootstrap-icons.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
import BootstrapVue3 from "bootstrap-vue-3";
import VueSplide from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import Default from "./layouts/default.vue";
import Blank from "./layouts/blank.vue";


// import VueGoogleMaps from "@fawmi/vue-google-maps";
const app = createApp(App)
  .use(IonicVue)
  .use(BootstrapVue3)
  .use(router)
  .use(store)
  .use(VueSplide)
//   .use(VueGoogleMaps, {
//     load: {
//       libraries: 'places,drawing,visualization',
//       language: 'ar',
//       region: 'LY',
//       key: 'AIzaSyCEuCV_HrhBwnVGFWRcd_T5w529RrUvzoc',
//     },
// })
  .component("blank-layout", Blank)
  .component("default-layout", Default);

router.isReady().then(() => {
  app.mount("#app");
});
