import {decryptText} from '../modules/crypt'
export default {
  namespaced: true,
  state: {
    isAuth: localStorage.getItem("access_token") !== null ? true : false,
    access_token: decryptText(localStorage.getItem("access_token")) ?? null,

  },
  
  getters: {
    loggedIn(state : any) {
      return state.isAuth;
    },

  },
};
