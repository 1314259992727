<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div class="card" data-card-height="cover" style="height: 812px">
        <div class="card-center text-center">
          <div class="content me-5 ms-5 mb-0">
            <img
              class="preload-img img-fluid ps-3 pe-3"
              src="../../assets/images/landcut.png"
              
            />
            
            <p class="mt-n1 color-highlight font-12">للتوصيل و الشحن السريع</p>
            <p class="boxed-text-xl">لاند كت، أنت على الطريق الصحيح</p>
            <router-link
              to="/login"
              class="btn scale-box btn-m mt-5 btn-center-l rounded-l shadow-xl bg-highlight font-800 text-uppercase"
              >بدء الاستخدام</router-link
            >
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage } from "@ionic/vue";
import { defineComponent } from "vue";
import { mapState } from "vuex";

export default defineComponent({
  created () {
    console.log(this.auth.firsttime);

  },
  name: "SplashPage",
  computed: {
    ...mapState(["auth"]),
  },
  components: {
    IonContent,
    IonPage,
  },
  methods: {

  },
});
</script>
