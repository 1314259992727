<template>
  <ion-page>
    <div id="page" style="display: block">
      <div class="page-content pb-0">
        <ion-router-outlet></ion-router-outlet>
      </div>
    </div>
  </ion-page>
</template>

<script>
import { IonPage, IonRouterOutlet } from "@ionic/vue";

export default {
  name: "blankPge",
  components: {
    IonPage,
    IonRouterOutlet,
  },
};
</script>
