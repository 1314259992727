import { checkValidToken,checkworkingstatus } from "../../modules/helpers";

export default function auth({ next, store }: any) {
  checkworkingstatus();

  if (!store.getters['auth/loggedIn']  ) {
    return next({
      path: "/splash",
    });
  }
  checkValidToken();
  return next();
}
