import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import store from "../store";

import { appRouter } from "./app";
import { authRouter } from "./auth";



const routes: Array<RouteRecordRaw> = [
  /* dashboard routes */
  ...appRouter,
  /* dashboard routes */
  /* Auth */
  ...authRouter
  /* Auth */
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {

  if (!to.meta.middleware) {
    return next();
  }
  const middleware = to.meta.middleware;

  const context = {
    to,
    from,
    next,
    store
  };
  return (middleware as any)[0]({
    ...context
  });

  
});

export default router
