
import { IonContent, IonPage, alertController } from "@ionic/vue";
import { defineComponent } from "vue";
import { instance } from "./../../modules/instance";
import { cryptText } from "./../../modules/crypt";
export default defineComponent({
  name: "HomePage",
  components: {
    IonContent,
    IonPage,
  },
  created() {
    this.token = localStorage.getItem("fcmtoken");
  },
  setup() {
    const presentAlert = async () => {
      const alert = await alertController.create({
        header: "تنبيه.!",
        subHeader: "معلومات الدخول غير صحيحة",
        message: "",
        buttons: ["OK"],
      });

      await alert.present();
    };

    return { presentAlert };
  },
  data() {
    return {
      phone: "",
      password: "",
      isLoading: false,
      needResendOtpCode: false,
      token: "" as any,
    };
  },

  methods: {
    loginUser(e: any) {
      e.preventDefault();

      this.isLoading = true;
      this.token = localStorage.getItem("fcmtoken");

      instance
        .post("/login", {
          phone: this.phone,
          password: this.password,
          token: this.token,
        })
        .then(function (this: any, response) {
          if (response.status == 200) {
            localStorage.setItem(
              "access_token",
              cryptText(response.data[0].access_token)
            );
            setTimeout(() => {
              location.reload();
            }, 230);
          }
        })
        .catch(() => {
          this.isLoading = false;
          (this.$refs.mybtr as any).click();
        });
    },
  },
});
