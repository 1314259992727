import { instance } from "./instance";
import router from "@/router";
import store from "@/store";
function logout() {
  try {
    const token = localStorage.getItem("fcmtoken");
    instance
      .post("/logout",{
        token: token,
      })
      .then(() => {
        localStorage.removeItem("access_token");
        store.commit("auth/logout");
      })
      .catch((err: any) => {
        if (err.response.status == 401) {
          localStorage.removeItem("access_token");
          store.commit("auth/logout");
        }
      });
  } catch (error) {
    localStorage.removeItem("access_token");
    router.push("/splash");
  }
}

function checkValidToken() {
  instance
    .get("/check-token")
    .then(() => {
      console.log("working fine>>>>>!");
    })
    .catch((err: any) => {
      console.log("rong>>>>>!" + err);
    });
}

function checkworkingstatus() {
  instance
    .get("/check-status")
    .then((response) => {
      store.commit("auth/checkstates", response.data.status);
    })
    .catch((err: any) => {
      console.log("err");

      router.push("/maintenancepage?error=" + err);
    });
}

function getstate(state: any) {
  if (state == 1) {
    return "في انتظار التاكيد";
  }
  if (state == 2) {
    return "في انتظار التجميع";
  }
  if (state == 3) {
    return "في انتظار الشحن";
  }
  if (state == 4) {
    return "في انتظار التسليم";
  }
  if (state == 5) {
    return "تم التسليم";
  }
  if (state == 6) {
    return "تم التسليم جزئي";
  }
  if (state == 7) {
    return "تم التسليم (مستحقات جاهزة)";
  }
  if (state == 8) {
    return "تعذر توصيله";
  }
  if (state == 9) {
    return "تم رفضها";
  }
  if (state == 10) {
    return "تخزين";
  }
  if (state == 11) {
    return "تاجيل";
  }
  if (state == 12) {
    return "ملغي";
  }
  if (state == 13) {
    return "استلام داخلي";
  }
}
function getstorestate(state: any, d: any) {
  if (d != 2) {
    if (state == 0) {
      return "في المخازن";
    }
    if (state == 1) {
      return "في عهدة المندوب";
    }
    if (state == 2) {
      return "عند الزبون";
    }
    if (state == 3) {
      return "في عهدة الناقل";
    }
    if (state == 4) {
      return "تالف";
    }
    if (state == 5) {
      return " (تحت التسوية)";
    }
    if (state == 6) {
      return " (تمت التسوية)";
    }
    if (state == 7) {
      return " ( تمت التسوية جزئي)";
    }
    if (state == 12) {
      return "";
    }
  } else {
    return "عند الزبون";
  }
}



export {
  getstate,
  getstorestate,
  logout,
  checkValidToken,
  checkworkingstatus,
};
