import Splash from "../views/starters/spalsh.vue";
import signup from "../views/starters/signup.vue";
import login from "../views/starters/login.vue";
import verify from "../views/starters/viryfyemail.vue";
import forget from "../views/starters/forget.vue";

import update from "../views/starters/update.vue";
import maintenance from "../views/starters/maintenance.vue";

import guest from "./middleware/guest";

const authMeta = { layout: "blank", middleware: [guest] };
export const authRouter = [
  {
    path: "/splash",
    name: "صفحة البداية",
    component: Splash,
    meta: authMeta
  },
  {
    path: "/login",
    name: "صفحة تسجيل الدخول",
    component: login,
    meta: authMeta
  },
  {
    path: "/signup",
    name: "صفحة تسجيل حساب",
    component: signup,
    meta: authMeta
  },
  {
    path: "/updatepage",
    name: "صفحة التخديث",
    component: update,
    meta: authMeta
  },
  {
    path: "/maintenancepage",
    name: "صفحة الصيانة",
    component: maintenance,
    meta: authMeta
  },
  {
    path: "/verify",
    name: "صفحة التاكيد",
    component: verify,
    meta: authMeta
  },

  {
    path: "/forget",
    name: "صفحة الاستعادة",
    component: forget,
    meta: authMeta
  },


];
