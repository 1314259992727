<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div class="card" data-card-height="cover" style="height: 812px">
        <div class="card-center">
          <div class="content text-center mb-0">
            <img
              class="preload-img img-fluid ps-3 pe-3"
              src="../../assets/images/landcut.png"
              
            />
            <h1 class="font-30 bt-5" style="margin-top: 25px">
              لاستعادة كلمة المرور
            </h1>
            <p class="text-center mb-5">
              الرجاء التواصل معي خدمات الزبائن 0919609191 .
            </p>
            <div class="text-center">
              <a
                href="https://www.facebook.com/Lnadcut"
                target="_blank"
                class="icon icon-s bg-facebook rounded-sm"
                ><i class="bi bi-facebook"></i
              ></a>
              <a  class="icon icon-s mx-2 bg-instagram rounded-sm"
                ><i class="bi bi-info-lg"></i
              ></a>

              <p class="font-12 pt-2">
                لي اي استفسار التواصل معا الدعم الفني او علي مواقع التواصل
                الاجتماعي
              </p>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage } from "@ionic/vue";
import { defineComponent } from "vue";
import { mapState } from "vuex";
import { instance } from "../../modules/instance";

export default defineComponent({
  created() {
    this.gethistory();
  },
  name: "SplashPage",
  computed: {
    ...mapState(["auth"]),
  },
  data() {
    return {
      code: "error",
    };
  },
  methods: {
    async gethistory() {
      instance
        .get("/check-status")
        .then((response) => {
          this.code = response.data.status;
        })
        .catch((err) => {
          console.log("err");
          this.code = err.message;
        });
    },
  },
  components: {
    IonContent,
    IonPage,
  },
});
</script>
