

const state = {
  accountid:'لا يوجد',
  shopname:'لا يوجد',
  personalid:'لا يوجد',
  shopphone:'لا يوجد',
}

const mutations = {
  updateemp(state :  any, data :any) {

    state.accountid = data.accountid;
    state.shopname = data.shopname;
    state.personalid = data.personalid;
    state.shopphone = data.shopphone;
},
}

const getters = {

}

export default {namespaced: true, state, mutations, getters };

