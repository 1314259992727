
import { IonApp } from "@ionic/vue";
import { defineComponent } from "vue";
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from "@capacitor/push-notifications";
import { SplashScreen } from '@capacitor/splash-screen';
const defaultLayout = "blank";
export default defineComponent({
  name: "App",
  computed: {
    layout(): any {
      return (this.$route.meta.layout || defaultLayout) + "-layout";
    },
  },

  data() {
    return {
      token: "" as any,
      msg: "" as any,
    };
  },

  methods:{
   async hidesplash(){
      await SplashScreen.hide();
    },


  },

  created() {
    let htmlEl = document.querySelector("html") as any;
    htmlEl.setAttribute("dir", "rtl");
    const cat = localStorage.getItem("Colormode");

    if (cat != null) {
      let bodyEl = document.querySelector("body") as any;
      bodyEl.classList.add(cat);
    } else {
      localStorage.setItem("Colormode", "theme-light");
      let bodyEl = document.querySelector("body") as any;
      bodyEl.classList.add("theme-light");
    }
    this.hidesplash();
  },

  mounted() {
    PushNotifications.requestPermissions().then((result) => {
      if (result.receive === "granted") {
        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    PushNotifications.addListener("registration", (token: Token) => {
      const tok = localStorage.getItem("fcmtoken");
      if (tok != null) {
        return "error";
      } else {
        localStorage.setItem("fcmtoken", token.value);
      }
      // Push Notifications registered successfully.
      // Send token details to API to keep in DB.
    });

    PushNotifications.addListener("registrationError", (error: any) => {
      // Handle push notification registration error here.
      console.log(error)
    });

    PushNotifications.addListener(
      "pushNotificationReceived",
      (notification: PushNotificationSchema) => {
        this.msg = notification;
        // Show the notification payload if the app is open on the device.
      }
    );

    PushNotifications.addListener(
      "pushNotificationActionPerformed",
      (notification: ActionPerformed) => {
        if (notification.notification.data.notitypy == "cancel") {
          this.$router.push({
            path: "/shipments-page",
            query: {
              package: notification.notification.data.value,
            },
          });
        }
        // Implement the needed action to take when user tap on a notification.
      }
    );
  },
  components: {
    IonApp,
  },
});
